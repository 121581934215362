import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISoloCharacter } from '../../modules/common/model/graphql-types';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { SoloCharacter } from '../../modules/solo/common/components/solo-character';

interface ISoloCharacterNodes {
  nodes: ISoloCharacter[];
}

interface ISoloCharacterEntry {
  allCharacters: ISoloCharacterNodes;
}

interface IProps {
  data: ISoloCharacterEntry;
}

const SoloCharsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Light',
            tooltip: 'Light',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_light.webp"
                width={24}
                alt="Light"
              />
            )
          },
          {
            value: 'Dark',
            tooltip: 'Dark',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_darkness.webp"
                width={24}
                alt="Dark"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_tank.webp"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Fighter',
            tooltip: 'Fighter',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_fighter.webp"
                width={24}
                alt="Fighter"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_mage.webp"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_assassin.webp"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_ranger.webp"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'Healer',
            tooltip: 'Healer',
            image: (
              <StaticImage
                src="../../images/solo/icons/class_healer.webp"
                width={24}
                alt="Healer"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout className={'generic-page characters-solo'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/solo/categories/category_characters.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Characters List</h1>
          <h2>
            All characters (Hunters) you can obtain in Solo Leveling: Arise!
            Check their skills, stats, weapons and more!
          </h2>
          <p>
            Last updated: <strong>14/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar solo">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container solo-cards`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <SoloCharacter
                  slug={emp.slug}
                  mode="card"
                  showLabel
                  showTags
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage src="../../images/solo/sad.webp" alt="No results" />
          <p>No characters found. Try changing your filter. </p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default SoloCharsPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Solo Leveling: Arise | Prydwen Institute"
    description="All characters (Hunters) you can obtain in Solo Leveling: Arise! Check their skills, stats, weapons and more!"
    game="solo"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulSoloCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        class
        isNew
        ratings {
          pve
        }
      }
    }
  }
`;
